import React from "react";
import { Container } from "@atoms";

const Definition = ({ word, wordType, pronunciation, lead, copy }) => {
  return (
    <div className="bg-carnation py-20 text-white">
      <Container variant="xs">
        <div className="flex flex-wrap gap-10">
          <div className="flex flex-col gap-10 sm:flex-row sm:gap-20">
            <div className="flex flex-col gap-3">
              <h2 className="mb-2 font-sans text-5xl">{word}</h2>
              <p className="font-heading text-sm">{wordType}</p>
              <p className="font-heading text-sm">{pronunciation}</p>
            </div>
            <div
              className="text-pretty font-heading text-3xl leading-tight"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: lead }}
            />
          </div>
          <div
            className="prose text-pretty font-heading text-lg font-medium leading-tight prose-p:text-white lg:text-xl"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        </div>
      </Container>
    </div>
  );
};

export default Definition;
